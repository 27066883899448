import { createApp } from "vue";
import { createPinia } from "pinia";
import { VueRecaptchaPlugin } from "vue-recaptcha";
import { createHead, VueHeadMixin } from "@unhead/vue";

import App from "./App.vue";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import BadgeDirective from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";
import FocusTrap from "primevue/focustrap";
import StyleClass from "primevue/styleclass";
import DialogService from "primevue/dialogservice";
import * as Sentry from "@sentry/vue";
import { initYandexMetrika } from "yandex-metrika-vue3";
import { YANDEX_METRIKA_ID, SENTRY_DSN, SENTRY_ENEBLED } from "@/shared/config";
import { router, i18n } from "./providers";
import "primeflex/primeflex.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "@he-tree/vue/style/default.css";
import "./styles/index.scss";
import "primeicons/primeicons.css";
import "floating-vue/dist/style.css";

const app = createApp(App);

const head = createHead();

if (SENTRY_ENEBLED) {
    Sentry.init({
        app,
        dsn: SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                networkDetailAllowUrls: [window.location.origin, "localhost", "https://stormbpmn.com/api/*"],
            }),
        ],

        tracesSampleRate: 0.1,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ["localhost", /^\/(?!\/)/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
        trackComponents: true,
    });
}

app.use(head);
app.use(createPinia());

app.use(VueRecaptchaPlugin, {
    v2SiteKey: import.meta.env.VITE_RECAPTCHA_TOKEN,
});
app.use(PrimeVue, { ripple: true });
app.use(router);
app.use(initYandexMetrika, {
    id: YANDEX_METRIKA_ID,
    router: router,
    env: import.meta.env.MODE,
    scriptSrc: "https://mc.yandex.ru/metrika/tag.js",
    debug: true,
});
app.use(i18n);
app.use(ToastService);
app.use(ConfirmationService);
app.use(DialogService);

app.mixin(VueHeadMixin);

app.directive("badge", BadgeDirective);
app.directive("tooltip", Tooltip);
app.directive("focustrap", FocusTrap);
app.directive("styleclass", StyleClass);

export default app;
